import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "use-with-netlify-identity"
    }}>{`Use with Netlify Identity`}</h1>
    <p>{`useAuth works with `}<a parentName="p" {...{
        "href": "https://github.com/netlify/netlify-identity-widget"
      }}>{`netlify-identity-widget`}</a>{`, which is designed as a zero-config login widget. You'll need 3 lines of configuration with useAuth.`}</p>
    <p>{`Example app 👉 `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/useAuth/tree/master/examples/useauth-gatsby-netlify-identity"
      }}>{`example/useauth-gatsby-netlify-identity`}</a></p>
    <h2 {...{
      "id": "1-set-up-netlify-identity"
    }}>{`1. Set up Netlify Identity`}</h2>
    <p>{`You'll need to enable Netlify Identity for your site. Go to `}<a parentName="p" {...{
        "href": "https://netlify.com"
      }}>{`netlify.com`}</a>{`, login, find your site, and enable identity.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/85e5d37583837eb764d90926dfb54b87/820f7/netlify-identity.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50.224215246636774%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'200\\'%20viewBox=\\'0%200%20400%20200\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%2035v35h9V51l1-18%20195-1h195v19c0%2011%201%204%201-16V0H0v35m378%2037c-2%202-1%205%202%205%201-1%202%200%202%202%202%203%202%204%200%205l-5%206c-2%204-4%205-6%205l-4%202c0%202%208%200%2010-2%202-1%202%200%202%2013-1%2020%200%2028%203%2028%201%200%202-1%202-5l1-4%202%204c4%207%206%204%203-5-2-7-3-8-2-12l1-19c-1-4%202-6%203-2%200%202%201%203%203%200%201-1%201-2-2-4l-3-4c1-1-1-6-3-10s-7-5-9-3M24%20165l1%207h52v-13H24v6\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/85e5d37583837eb764d90926dfb54b87/ca0a1/netlify-identity.webp 223w", "/static/85e5d37583837eb764d90926dfb54b87/75680/netlify-identity.webp 445w", "/static/85e5d37583837eb764d90926dfb54b87/8d1ba/netlify-identity.webp 890w", "/static/85e5d37583837eb764d90926dfb54b87/3838e/netlify-identity.webp 1335w", "/static/85e5d37583837eb764d90926dfb54b87/e11e5/netlify-identity.webp 1780w", "/static/85e5d37583837eb764d90926dfb54b87/d8ed4/netlify-identity.webp 2126w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/85e5d37583837eb764d90926dfb54b87/e92b6/netlify-identity.png 223w", "/static/85e5d37583837eb764d90926dfb54b87/e66bf/netlify-identity.png 445w", "/static/85e5d37583837eb764d90926dfb54b87/4ef49/netlify-identity.png 890w", "/static/85e5d37583837eb764d90926dfb54b87/4e814/netlify-identity.png 1335w", "/static/85e5d37583837eb764d90926dfb54b87/701e9/netlify-identity.png 1780w", "/static/85e5d37583837eb764d90926dfb54b87/820f7/netlify-identity.png 2126w"],
                "sizes": "(max-width: 890px) 100vw, 890px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/85e5d37583837eb764d90926dfb54b87/4ef49/netlify-identity.png",
                "alt": "Enable Netlify Identity",
                "title": "Enable Netlify Identity",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Enable Netlify Identity`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "2-install-dependencies"
    }}>{`2. Install dependencies`}</h2>
    <p>{`Install both useAuth and netlify-identity-widget. We put providers in peer dependencies to reduce package sizes :)`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add react-use-auth netlify-identity-widget
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install react-use-auth netlify-identity-widget
`}</code></pre>
    <h2 {...{
      "id": "3-configure-useauth"
    }}>{`3. Configure useAuth`}</h2>
    <p>{`Netlify Identity is meant to be zero-config, but you need to configure `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` itself. It needs to know you're using Netlify Identity and how to navigate on your site.`}</p>
    <h3 {...{
      "id": "with-gatsby"
    }}>{`With Gatsby`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// gatsby-browser.js

import { AuthConfig } from "react-use-auth";
import { NetlifyIdentity } from "react-use-auth/netlify-identity";
import { navigate } from "gatsby";

export const wrapPageElement = ({ element }) => (
    <>
        <AuthConfig authProvider={NetlifyIdentity} navigate={navigate} />
        {element}
    </>
);
`}</code></pre>
    <h3 {...{
      "id": "with-nextjs"
    }}>{`With NextJS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// pages/_app.js

import { AuthConfig } from "react-use-auth";
import { NetlifyIdentity } from "react-use-auth/netlify-identity";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
    const router = useRouter();

    return (
        <>
            <AuthConfig
                authProvider={NetlifyIdentity}
                navigate={(url) => router.push(url)}
            />
            <Component {...pageProps} />
        </>
    );
}
`}</code></pre>
    <h2 {...{
      "id": "4-enjoy-"
    }}>{`4. Enjoy 😊`}</h2>
    <p>{`You're ready to use `}<inlineCode parentName="p">{`useAuth`}</inlineCode>{` anywhere on your site. Check the `}<a parentName="p" {...{
        "href": "/docs/api-reference"
      }}>{`API Reference`}</a>{` for more detail.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Login = () => {
    const { isAuthenticated, login, logout } = useAuth();

    if (isAuthenticated()) {
        return <button onClick={logout}>Logout</Button>;
    } else {
        return <button onClick={login}>Login</Button>;
    }
};
`}</code></pre>
    <p>{`Users get a widget like this:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "806px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/52dd2593875fbf2e78584e7e0e1457d3/764be/netlify-identity-widget.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "121.97309417040358%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'488\\'%20viewBox=\\'0%200%20400%20488\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20244v244h401V0H0v244M20%2019l-2%203v441l2%202%203%202h352l2-2%202-3V21l-2-2-3-2H22l-2%202m178%2058l75%201a1029%201029%200%2000-1-2l-74%201M62%20129v5h15v-9l-2%202c-2%202-2%203%200%204l1%202-3-1h-8l-2%201%201-2c2-1%202-2%201-3l-2-2%203%202%204%203%203-3%203-4H62v5m-11%2089c-2%202-1%2038%201%2038h294c2-1%203-33%201-37l-2-2H199l-148%201m-1%20113l149%201a4183%204183%200%20000-2l-149%201m62%2045v6h4c5%200%206-1%206-6%200-4-2-6-7-6h-3v6m12%200c-2%204%204%208%207%204%201-1%201-1-1-1h-3c-1-1%200-1%202-1s3-1%203-2l2%202c1%204%204%205%205%202%202-6%202-7%201-7l-3%203v3l-1-3c-1-3-4-4-4-2h-1c-2-2-6-1-7%202\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/52dd2593875fbf2e78584e7e0e1457d3/ca0a1/netlify-identity-widget.webp 223w", "/static/52dd2593875fbf2e78584e7e0e1457d3/75680/netlify-identity-widget.webp 445w", "/static/52dd2593875fbf2e78584e7e0e1457d3/e94a4/netlify-identity-widget.webp 806w"],
                "sizes": "(max-width: 806px) 100vw, 806px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/52dd2593875fbf2e78584e7e0e1457d3/e92b6/netlify-identity-widget.png 223w", "/static/52dd2593875fbf2e78584e7e0e1457d3/e66bf/netlify-identity-widget.png 445w", "/static/52dd2593875fbf2e78584e7e0e1457d3/764be/netlify-identity-widget.png 806w"],
                "sizes": "(max-width: 806px) 100vw, 806px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/52dd2593875fbf2e78584e7e0e1457d3/764be/netlify-identity-widget.png",
                "alt": "Netlify Identity login widget",
                "title": "Netlify Identity login widget",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Netlify Identity login widget`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      